import React, { useState } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calculadora from './components/Calculadora/Calculadora';
import ContentText from './components/ContentText/ContentText';
import Header from './components/Header/Header';
import moneyImage from './assets/Enroque_dinero.png';

function App() {
  const [backgroundImage, setBackgroundImage] = useState(moneyImage);
  const [activeButton, setActiveButton] = useState(null);

  return (
    <div className="App">
      <Header />
      <main style={{ backgroundImage: `url(${backgroundImage})` }} className="background">
        <div className="CalculadoraPage">
          <ContentText />
          <Calculadora
            changeBackground={setBackgroundImage}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
          />
          <ToastContainer />
        </div>
      </main>
    </div>
  );
}

export default App;
