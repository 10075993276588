import React from 'react'

import "./ContentText.scss"

const ContentText = () => {
  return (
    <div className='content'>
        <div className='contentSubtitulo'>

            <div className='divAzul'/>

            <div>
                <p>Alcanza tus sueños</p>
            </div>

        </div>
        <h3>Calculadora de Sueños</h3>
        <p>Si sabes lo <strong>que quieres</strong> y si sabes lo <strong> que cuesta</strong>, nosotros te ayudamos a <strong> que lo tengas</strong>.</p>

    </div>
  )
}

export default ContentText