import React from 'react'

import "./Header.scss"

const Header = () => {
    const logo = require('../../assets/Group 4213.png');

  return (
    <div className='Header'>
      <a href='https://enroque.mx/'>
        <img src={logo} alt="Logo_Enroque.mx" className='logo' />
      </a>
        
    </div>
  )
}

export default Header