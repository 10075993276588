import React from 'react'
import Formulario from './Formulario.jsx'

import Dinero from "../../assets/Enroque_dinero.png";
import Auto from "../../assets/Enroque_Auto.png";
import Viaje from "../../assets/Enroque_Viaje.png";
import Crucero from "../../assets/Enroque_Crucero.png";
import Casa from "../../assets/Enroque_Casa.png";
import Retiro from "../../assets/Enroque_Retiro.png";

import "./Calculadora.scss"

const Calculadora = ({ changeBackground, activeButton, setActiveButton  }) => {

  const handleButtonHover = (imageUrl, buttonId) => {
    changeBackground(imageUrl);
    setActiveButton(buttonId);
  };

  const resetBackground = () => {
    if (activeButton === null) {
      changeBackground(Dinero);
    }
  };

    let logoSimple = require("../../assets/Logo Simple.png");
    
  return (
    <div className='Calculadora'>
        <h2>Sueño a <strong>Realizar</strong> </h2>
        <div className='containerWrap'>
            <div>
              <button 
              className={activeButton === 'btn1' ? 'activeButton' : ''}
              id='btn1'
              onMouseEnter={() => handleButtonHover(Dinero, 'btn1')}
              onMouseLeave={resetBackground}
              >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1306_1877)">
                    <path d="M12 2.10071C6.48 2.10071 2 6.58071 2 12.1007C2 17.6207 6.48 22.1007 12 22.1007C17.52 22.1007 22 17.6207 22 12.1007C22 6.58071 17.52 2.10071 12 2.10071ZM13.41 18.1907V18.7707C13.41 19.5007 12.81 20.1007 12.08 20.1007H12.07C11.34 20.1007 10.74 19.5007 10.74 18.7707V18.1707C9.41 17.8907 8.23 17.1607 7.73 15.9307C7.5 15.3807 7.93 14.7707 8.53 14.7707H8.77C9.14 14.7707 9.44 15.0207 9.58 15.3707C9.87 16.1207 10.63 16.6407 12.09 16.6407C14.05 16.6407 14.49 15.6607 14.49 15.0507C14.49 14.2207 14.05 13.4407 11.82 12.9107C9.34 12.3107 7.64 11.2907 7.64 9.24071C7.64 7.52071 9.03 6.40071 10.75 6.03071V5.43071C10.75 4.70071 11.35 4.10071 12.08 4.10071H12.09C12.82 4.10071 13.42 4.70071 13.42 5.43071V6.05071C14.8 6.39071 15.67 7.25071 16.05 8.31071C16.25 8.86071 15.83 9.44071 15.24 9.44071H14.98C14.61 9.44071 14.31 9.18071 14.21 8.82071C13.98 8.06071 13.35 7.57071 12.09 7.57071C10.59 7.57071 9.69 8.25071 9.69 9.21071C9.69 10.0507 10.34 10.6007 12.36 11.1207C14.38 11.6407 16.54 12.5107 16.54 15.0307C16.52 16.8607 15.15 17.8607 13.41 18.1907V18.1907Z" fill="#AEAEAE"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1306_1877">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.100708)"/>
                  </clipPath>
                  </defs>
               </svg>

               <span>Dinero</span>
             </button>
            </div>
            <div>
                <button 
                className={activeButton === 'btn2' ? 'activeButton' : ''}
                id='btn2'
                onMouseEnter={() => handleButtonHover(Auto, 'btn2')}
                onMouseLeave={resetBackground}
                >
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1306_2393)">
                    <path d="M18.92 6.11071C18.72 5.52071 18.16 5.10071 17.5 5.10071H6.5C5.84 5.10071 5.29 5.52071 5.08 6.11071L3.11 11.7807C3.04 11.9907 3 12.2107 3 12.4407V19.6007C3 20.4307 3.67 21.1007 4.5 21.1007C5.33 21.1007 6 20.4307 6 19.6007V19.1007H18V19.6007C18 20.4207 18.67 21.1007 19.5 21.1007C20.32 21.1007 21 20.4307 21 19.6007V12.4407C21 12.2207 20.96 11.9907 20.89 11.7807L18.92 6.11071ZM6.5 16.1007C5.67 16.1007 5 15.4307 5 14.6007C5 13.7707 5.67 13.1007 6.5 13.1007C7.33 13.1007 8 13.7707 8 14.6007C8 15.4307 7.33 16.1007 6.5 16.1007ZM17.5 16.1007C16.67 16.1007 16 15.4307 16 14.6007C16 13.7707 16.67 13.1007 17.5 13.1007C18.33 13.1007 19 13.7707 19 14.6007C19 15.4307 18.33 16.1007 17.5 16.1007ZM5 11.1007L6.27 7.28071C6.41 6.88071 6.79 6.60071 7.22 6.60071H16.78C17.21 6.60071 17.59 6.88071 17.73 7.28071L19 11.1007H5Z" fill="#AEAEAE"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1306_2393">
                     <rect width="24" height="24" fill="white" transform="translate(0 0.100708)"/>
                    </clipPath>
                  </defs>
                </svg>
                <span>Auto</span>
                </button>
            </div>
            <div>
                <button 
                className={activeButton === 'btn3' ? 'activeButton' : ''}
                id='btn3'
                onMouseEnter={() => handleButtonHover(Viaje, 'btn3')}
                onMouseLeave={resetBackground}
                >
                   <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1306_2400)">
                        <path d="M21.48 13.8007L13.5 9.10071V3.60071C13.5 2.77071 12.83 2.10071 12 2.10071C11.17 2.10071 10.5 2.77071 10.5 3.60071V9.10071L2.52 13.8007C2.2 13.9807 2 14.3307 2 14.7007C2 15.4007 2.67 15.9007 3.34 15.7107L10.5 13.6107V19.1007L8.24 20.4507C8.09 20.5407 8 20.7107 8 20.8807V21.3807V21.4607C8 21.7907 8.31 22.0307 8.62 21.9507L11.54 21.2207L12 21.1007L12.38 21.1907L12.8 21.3007L14.7 21.7807L15.37 21.9507C15.69 22.0307 15.99 21.7907 15.99 21.4607V21.0907V20.8807C15.99 20.7007 15.9 20.5407 15.75 20.4507L13.5 19.1007V13.6007L20.66 15.7007C21.33 15.9007 22 15.4007 22 14.7007C22 14.3307 21.8 13.9807 21.48 13.8007Z" fill="#AEAEAE"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_1306_2400">
                        <rect width="24" height="24" fill="white" transform="translate(0 0.100708)"/>
                      </clipPath>
                      </defs>
                   </svg>
                    <span>Viaje en pareja</span>
                </button>
            </div>
            <div>
                <button 
                className={activeButton === 'btn4' ? 'activeButton' : ''}
                id='btn4'
                onMouseEnter={() => handleButtonHover(Crucero, 'btn4')}
                onMouseLeave={resetBackground}
                >
                  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.7667 1.37293C11.7667 0.8152 11.3129 0.36145 10.7552 0.36145H9.24586C8.68812 0.36145 8.23438 0.8152 8.23438 1.37293V2.88516H11.7667V1.37293Z" fill="#AEAEAE"/>
                    <path d="M10.1476 6.68955L16.0107 8.2215V4.44068C16.0107 4.22912 15.8386 4.05701 15.627 4.05701H4.37195C4.16039 4.05701 3.98828 4.22912 3.98828 4.44068V8.2215L9.85137 6.68955C9.94844 6.66419 10.0505 6.66419 10.1476 6.68955Z" fill="#AEAEAE"/>
                    <path d="M19.414 16.6681C18.7673 16.6681 18.4756 16.5228 18.1062 16.3389C17.9329 16.2525 17.75 16.1615 17.5318 16.0839C17.2289 15.9762 16.8576 15.8946 16.3489 15.8874L18.4942 10.4869C18.5417 10.3587 18.5037 10.2519 18.4767 10.1994C18.4494 10.1466 18.3837 10.0526 18.2496 10.0176L9.99996 7.86206L1.75039 10.0176C1.61625 10.0526 1.55051 10.1466 1.52328 10.1994C1.49621 10.2519 1.4582 10.3588 1.50582 10.4871L3.64633 15.8875C3.13781 15.8951 2.76695 15.9773 2.46422 16.0854C2.24785 16.1626 2.06617 16.2531 1.89387 16.3389C1.52445 16.5228 1.2327 16.6681 0.585938 16.6681C0.262344 16.6681 0 16.9305 0 17.2541C0 17.5776 0.262344 17.84 0.585938 17.84C1.50828 17.84 1.99063 17.5998 2.41617 17.388C2.58199 17.3054 2.73234 17.2308 2.90598 17.1735C3.11922 17.103 3.36762 17.0588 3.7241 17.0588C3.80547 17.0588 3.88117 17.0611 3.95219 17.0655C4.44547 17.096 4.7091 17.2272 5.03203 17.388C5.45758 17.5999 5.93992 17.84 6.86227 17.84C7.78457 17.84 8.26687 17.5999 8.69246 17.388C9.06184 17.2041 9.35359 17.0588 10.0003 17.0588C10.647 17.0588 10.9387 17.2041 11.308 17.388C11.7336 17.5999 12.2159 17.84 13.1382 17.84C14.0605 17.84 14.5428 17.5999 14.9684 17.388C15.2923 17.2267 15.5566 17.0952 16.0529 17.0652C16.1225 17.061 16.1966 17.0588 16.2762 17.0588C16.6287 17.0588 16.8755 17.1022 17.0872 17.1713C17.2638 17.2289 17.416 17.3044 17.5839 17.388C18.0095 17.5999 18.4918 17.84 19.4141 17.84C19.7377 17.84 20 17.5777 20 17.2541C20 16.9305 19.7376 16.6681 19.414 16.6681ZM4.58289 10.8607L9.85184 9.48394C9.94895 9.45858 10.051 9.45858 10.1481 9.48394L15.417 10.8607C15.7301 10.9425 15.9176 11.2626 15.8358 11.5757C15.767 11.8391 15.5294 12.0137 15.2693 12.0137C15.2203 12.0137 15.1705 12.0075 15.1208 11.9945L9.99996 10.6565L4.87914 11.9945C4.56605 12.0763 4.2459 11.8888 4.1641 11.5757C4.0823 11.2626 4.2698 10.9425 4.58289 10.8607Z" fill="#AEAEAE"/>
                  </svg>

                  <span>Crucero</span>

                </button>
            </div>
            <div>
                <button 
                className={activeButton === 'btn5' ? 'activeButton' : ''}
                id='btn5'
                onMouseEnter={() => handleButtonHover(Casa, 'btn5')}
                onMouseLeave={resetBackground}
                >
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1306_2208)">
                      <path d="M19.0006 9.40068V5.10068C19.0006 4.55068 18.5506 4.10068 18.0006 4.10068H17.0006C16.4506 4.10068 16.0006 4.55068 16.0006 5.10068V6.70068L12.6706 3.70068C12.2906 3.36068 11.7106 3.36068 11.3306 3.70068L2.97059 11.2307C2.63059 11.5307 2.84059 12.1007 3.30059 12.1007H5.00059V19.1007C5.00059 19.6507 5.45059 20.1007 6.00059 20.1007H9.00059C9.55059 20.1007 10.0006 19.6507 10.0006 19.1007V14.1007H14.0006V19.1007C14.0006 19.6507 14.4506 20.1007 15.0006 20.1007H18.0006C18.5506 20.1007 19.0006 19.6507 19.0006 19.1007V12.1007H20.7006C21.1606 12.1007 21.3806 11.5307 21.0306 11.2307L19.0006 9.40068ZM10.0006 10.1007C10.0006 9.00068 10.9006 8.10068 12.0006 8.10068C13.1006 8.10068 14.0006 9.00068 14.0006 10.1007H10.0006Z" fill="#AEAEAE"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1306_2208">
                        <rect width="24" height="24" fill="white" transform="translate(0 0.100708)"/>
                      </clipPath>
                    </defs>
                  </svg>

                  <span>Casa</span>

                </button>
            </div>
            <div>
                <button 
                className={activeButton === 'btn6' ? 'activeButton' : ''}
                id='btn6'
                onMouseEnter={() => handleButtonHover(Retiro, 'btn6')}
                onMouseLeave={resetBackground}
                >
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1306_2509)">
                      <path d="M9.5 5.60071C10.6 5.60071 11.5 4.70071 11.5 3.60071C11.5 2.50071 10.6 1.60071 9.5 1.60071C8.4 1.60071 7.5 2.50071 7.5 3.60071C7.5 4.70071 8.4 5.60071 9.5 5.60071ZM5.75 9.00071L3.23 21.9107C3.11 22.5307 3.58 23.1007 4.21 23.1007H4.3C4.77 23.1007 5.18 22.7707 5.28 22.3107L6.85 15.1007L9 17.1007V22.1007C9 22.6507 9.45 23.1007 10 23.1007C10.55 23.1007 11 22.6507 11 22.1007V15.9607C11 15.6907 10.89 15.4407 10.71 15.2507L8.95 13.5007L9.55 10.5007C10.62 11.8207 12.13 12.7307 13.86 13.0107C14.46 13.1107 15 12.6207 15 12.0107C15 11.5207 14.64 11.1107 14.16 11.0307C12.67 10.7807 11.41 9.88071 10.65 8.65071L9.7 7.05071C9.35 6.45071 8.7 6.10071 8 6.10071C7.75 6.10071 7.5 6.15071 7.25 6.25071L2.62 8.15071C2.25 8.30071 2 8.67071 2 9.07071V12.1007C2 12.6507 2.45 13.1007 3 13.1007C3.55 13.1007 4 12.6507 4 12.1007V9.75071L5.75 9.00071ZM21 2.10071H14C13.45 2.10071 13 2.55071 13 3.10071V8.10071C13 8.65071 13.45 9.10071 14 9.10071H16.75V22.3507C16.75 22.7607 17.09 23.1007 17.5 23.1007C17.91 23.1007 18.25 22.7607 18.25 22.3507V9.10071H21C21.55 9.10071 22 8.65071 22 8.10071V3.10071C22 2.55071 21.55 2.10071 21 2.10071ZM20.15 5.95071L18.87 7.24071C18.56 7.56071 18.02 7.33071 18.02 6.89071V6.35071H15.26C14.85 6.35071 14.51 6.01071 14.51 5.60071C14.51 5.19071 14.85 4.85071 15.26 4.85071H18.02V4.31071C18.02 3.86071 18.56 3.64071 18.87 3.96071L20.15 5.25071C20.34 5.44071 20.34 5.76071 20.15 5.95071Z" fill="#AEAEAE"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1306_2509">
                        <rect width="24" height="24" fill="white" transform="translate(0 0.100708)"/>
                      </clipPath>
                    </defs>
                  </svg>

                  <span>Retiro</span>

                </button>
            </div>
        </div>
        <Formulario selectedDream={activeButton} />
        <img src={logoSimple} alt='Logo Enroque' className='LogoSimple'></img>
    </div>
  )
}

export default Calculadora