import React, { useState } from 'react';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/inject-style';

import "./Calculadora.scss";

const Formulario = ({ selectedDream }) => {
    const [currentValue, setCurrentValue] = useState('');
    const [currentAge, setCurrentAge] = useState('');
    const [targetAge, setTargetAge] = useState('');
    const [fullName, setFullName] = useState('');

    const formatValue = (value) => {
        const unformattedValue = value.replace(/\D/g, '');
        let formattedValue = unformattedValue;
        if (unformattedValue.length > 3) {
            formattedValue = unformattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ', ');
        }
        return formattedValue;
    };

    const handleValueChange = (e) => {
        const value = e.target.value;
        const formattedValue = formatValue(value);
        setCurrentValue(formattedValue);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (currentValue === '' || currentAge === '' || targetAge === '' || fullName === '') {
            toast.error('Por favor complete todos los campos antes de enviar.', {
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            const buttonNames = {
                btn1: 'Ahorrar dinero',
                btn2: 'Comprarme un auto',
                btn3: 'Viajar en pareja',
                btn4: 'Ir de crucero',
                btn5: 'Comprar mi casa',
                btn6: 'Retirarme'
            };
    
            const dream = buttonNames[selectedDream];
            const message = `Hola Enroque, \nMe llamo ${fullName}\n\nTengo ${currentAge} y quiero a los ${targetAge} años tener ${currentValue} MXN para ${dream}.\n\nVengo de: http://calculadora.enroque.mx/`;
            const url = `https://api.whatsapp.com/send?phone=5218712639246&text=${encodeURIComponent(message)}`;
            window.open(url, '_blank');
        }
    };

    return (
        <div className='ContentFormulario'>
            <form onSubmit={handleSubmit} className='Formulario'>
                <div>
                    <label>Valor aproximado de tu sueño actual</label>
                    {/*<input
                        type="number"
                        placeholder="Ingresa el valor en MXN"
                        value={currentValue}
                        onChange={(e) => setCurrentValue(e.target.value)}
    />*/}
                    <input
                        type="text"
                        placeholder="Ingresa el valor en MXN"
                        value={currentValue}
                        onChange={handleValueChange}
                    />
                </div>
                <div>
                    <label>Edad actual</label>
                    <input
                        type="number"
                        placeholder="Ej. 45"
                        value={currentAge}
                        onChange={(e) => setCurrentAge(e.target.value)}
                    />
                </div>
                <div>
                    <label>Edad objetivo para tener el sueño realizado</label>
                    <input
                        type="number"
                        placeholder="Ej. 65"
                        value={targetAge}
                        onChange={(e) => setTargetAge(e.target.value)}
                    />
                </div>
                <div>
                    <label>Tu nombre completo</label>
                    <input
                        type="text"
                        placeholder="Ej. Mariano López Reyero"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </div>
                <button type="submit">
                    <span>Solicitar</span>
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clipPath="url(#clip0_1306_2580)">
                           <path d="M18.0738 14.502L18.0648 14.5772C15.8598 13.4782 15.6291 13.3318 15.3444 13.759C15.1468 14.0548 14.5713 14.7256 14.3978 14.9242C14.2223 15.1197 14.0478 15.1347 13.75 14.9994C13.4492 14.849 12.4836 14.5331 11.3405 13.5103C10.45 12.7131 9.8524 11.7355 9.67592 11.4346C9.38212 10.9273 9.99679 10.8551 10.5563 9.79618C10.6566 9.58561 10.6055 9.42015 10.5313 9.27075C10.456 9.12034 9.85741 7.64632 9.60673 7.05872C9.36607 6.47312 9.1184 6.54732 8.93289 6.54732C8.35532 6.49719 7.93317 6.50521 7.56115 6.89226C5.94274 8.67111 6.35086 10.5061 7.73563 12.4574C10.457 16.0191 11.907 16.6749 14.5582 17.5854C15.2742 17.813 15.927 17.7809 16.4434 17.7067C17.0189 17.6155 18.2152 16.9838 18.4649 16.2768C18.7206 15.5699 18.7206 14.9833 18.6454 14.8479C18.5712 14.7126 18.3746 14.6374 18.0738 14.502Z" fill="white"/>
                           <path d="M21.0956 3.61267C13.3856 -3.84065 0.625821 1.56509 0.620807 12.0797C0.620807 14.1815 1.17131 16.2311 2.22017 18.041L0.519531 24.2198L6.87184 22.5633C14.7984 26.845 24.5811 21.1595 24.5851 12.0858C24.5851 8.90108 23.3417 5.90391 21.0806 3.65177L21.0956 3.61267ZM22.5817 12.0527C22.5756 19.7065 14.1737 24.4866 7.52863 20.5799L7.16765 20.3653L3.4074 21.343L4.41515 17.688L4.1755 17.312C0.0402247 10.7291 4.79117 2.12562 12.6245 2.12562C15.2858 2.12562 17.7836 3.16344 19.6647 5.04357C21.5448 6.90765 22.5817 9.40546 22.5817 12.0527Z" fill="white"/>
                        </g>
                        <defs>
                           <clipPath id="clip0_1306_2580">
                               <rect width="24.0656" height="24.0656" fill="white" transform="translate(0.519531 0.154785)"/>
                           </clipPath>
                        </defs>
                    </svg>
                </button>
            </form>
            <ToastContainer />
        </div>
    );
};

export default Formulario;